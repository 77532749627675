import { useCallback, useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { HomeBanner, CourseOutlineInstitute } from '@ascd/witsby-components';
import { Box } from '@mui/material';
import get from 'lodash/get';
import { AppContext } from '@contexts/appContext';
import GET_IMAGE_URL from '@graphql/schema/getImageUrl.graphql';
import {
  getBannerText,
  getBannerCoverImage,
  detectDeviceIsTablet,
  detectDeviceIsMobile,
} from '@utils';

const SubHeader = () => {
  const apolloClient = useApolloClient();
  const isMobile = detectDeviceIsMobile();
  const isTablet = detectDeviceIsTablet();
  const {
    state: { currentInstitution, ascdInstitutionSettings },
  } = useContext(AppContext);

  const [logoUrl, setLogoUrl] = useState('');
  const [bannerImageUrl, setBannerImageUrl] = useState('');
  const [bannerCoverImageUrl, setBannerCoverImageUrl] = useState('');

  const fetchImage = useCallback(
    async (imageName: string) => {
      const MINUTE_MS = 60000;
      const defaultCacheImages = localStorage.getItem('images');
      let localStorageImages = defaultCacheImages ? JSON.parse(defaultCacheImages) : {};
      if (localStorageImages[`${imageName}`]) return localStorageImages[`${imageName}`].image;
      const response = await apolloClient.query({
        query: GET_IMAGE_URL,
        variables: { imageName },
        fetchPolicy: 'network-only',
      });

      const image = response?.data?.getImageUrl?.url || '';
      const currentTime = new Date();
      localStorageImages = {
        ...localStorageImages,
        [imageName]: {
          image,
          expiry: currentTime.getTime() + MINUTE_MS * 14,
        },
      };

      localStorage.setItem('images', JSON.stringify(localStorageImages));
      return image;
    },
    [apolloClient],
  );

  useEffect(() => {
    if (currentInstitution.iconSrc) {
      const isValidUrl =
        currentInstitution.iconSrc.startsWith('http') ||
        currentInstitution.iconSrc.startsWith('https');
      if (isValidUrl) {
        setLogoUrl(currentInstitution.iconSrc);
      } else {
        fetchImage(currentInstitution.iconSrc).then((url) => setLogoUrl(url));
      }
    } else {
      setLogoUrl('');
    }
  }, [currentInstitution.iconSrc, fetchImage]);

  useEffect(() => {
    if (ascdInstitutionSettings?.settings?.homeBannerImage?.image) {
      const bannerImageSrc = ascdInstitutionSettings.settings.homeBannerImage.image;
      const isValidUrl = bannerImageSrc.startsWith('http') || bannerImageSrc.startsWith('https');
      if (isValidUrl) {
        setBannerImageUrl(bannerImageSrc);
      } else {
        fetchImage(bannerImageSrc).then((url) => setBannerImageUrl(url));
      }
    } else {
      setBannerImageUrl('');
    }
  }, [ascdInstitutionSettings?.settings?.homeBannerImage?.image, fetchImage]);

  useEffect(() => {
    if (ascdInstitutionSettings?.settings?.homeBannerImage?.coverImage) {
      const bannerImageSrc = ascdInstitutionSettings.settings.homeBannerImage.coverImage;
      const isValidUrl = bannerImageSrc.startsWith('http') || bannerImageSrc.startsWith('https');
      if (isValidUrl) {
        setBannerCoverImageUrl(bannerImageSrc);
      } else {
        fetchImage(bannerImageSrc).then((url) => setBannerCoverImageUrl(url));
      }
    } else {
      setBannerCoverImageUrl('');
    }
  }, [ascdInstitutionSettings?.settings?.homeBannerImage?.coverImage, fetchImage]);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          height: '115px',
        },

        [theme.breakpoints.down('lg')]: {
          gap: '10px',
        },
      })}>
      {!isMobile && (
        <Box
          sx={(theme) => ({
            width: isTablet ? 'calc((100vw - 550px)/2)' : ' calc((100vw - 867px)/2)',

            [theme.breakpoints.up('lg')]: {
              minWidth: '320px',
              marginLeft: '1%',
            },
            [theme.breakpoints.between('sm', 'lg')]: {
              minWidth: '264px',
            },
            '& .css-6gwxb4': {
              pr: 0,
            },
          })}>
          <CourseOutlineInstitute
            image={logoUrl || '/ascd_avatar.png'}
            title={currentInstitution.name || ''}
            sx={{
              pl: 1,
              pt: 1,
              width: '254px',
              minWidth: '100px',
              minHeight: '100px',
              '& img': {
                maxWidth: '100px',
                maxHeight: '100px',
                // maxWidth: '100%',
              },
            }}
          />
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        sx={(theme) => ({
          '& .css-15wx5ab-MuiTypography-root': {
            [theme.breakpoints.up('lg')]: {
              fontSize: '15px',
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '12px',
            },
          },
          '& .css-1moyauk-MuiTypography-root': {
            [theme.breakpoints.up('lg')]: {
              fontSize: '22px',
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '18px',
            },
          },
        })}>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('lg')]: {
              width: 'calc(100vw - ((100vw - 867px)/2))',
              '& .image1': {
                width: '154px !important',
                height: '84.47px',
              },
              '& .image2': {
                width: 'auto !important',
                height: 'auto !important',
              },
            },
            [theme.breakpoints.between('md', 'lg')]: {
              width: 'calc(100vw - 270px)',
              '& .image1': {
                width: '130px !important',
                height: '72px !important',
              },
              '& .image2': {
                width: 'auto !important',
                height: 'auto !important',
              },
            },
            [theme.breakpoints.between('sm', 'md')]: {
              // width: 'calc(100vw - ((100vw - 64)/2))',
              width: 'calc(100vw - ((100vw - 350px)/2))',
              '& .image1': {
                width: '130px !important',
                height: '72px !important',
              },
              '& .image2': {
                width: 'auto !important',
                height: 'auto !important',
              },
            },
            [theme.breakpoints.down('sm')]: {
              width: '100vw',
            },
          })}>
          <HomeBanner
            image={bannerImageUrl}
            coverUrl={getBannerCoverImage(
              get(ascdInstitutionSettings, 'settings.homeBannerImage') ||
                get(currentInstitution, 'settings.homeBannerImage'),
              bannerCoverImageUrl,
            )}
            title={getBannerText(
              get(ascdInstitutionSettings, 'settings.homeBannerImage') ||
                get(currentInstitution, 'settings.homeBannerImage'),
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SubHeader;
