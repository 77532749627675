import { useContext, useMemo, useRef } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { IUserLearningItemCollection, LearningItemCardList } from '@ascd/witsby-components';

import { Grid } from '@mui/material';
import get from 'lodash/get';
import { HOME_SECTION_NAME } from '@constants';
import { AppContext } from '@contexts/appContext';
import { contentfulDirectClient } from '@graphql/apolloClient';
import EBOOKS from '@graphql/schema/ebooks.graphql';
import GET_EBOOK_URL from '@graphql/schema/getEbookUrl.graphql';
import GET_IMAGE_URL from '@graphql/schema/getImageUrl.graphql';
import GET_PUB_ISSUE from '@graphql/schema/getPubIssue.graphql';
import LIVE_EVENTS from '@graphql/schema/liveEvents.graphql';
import LOG_EVENTS from '@graphql/schema/mutations/logEvents.graphql';
import PODCAST from '@graphql/schema/podcast.graphql';
import USER_LEARNING_ITEM_COLLECTIONS from '@graphql/schema/userLearningItemCollections.graphql';
import VIDEO from '@graphql/schema/video.graphql';
import WEBINAR from '@graphql/schema/webinar.graphql';

import { ICourse, IEBook, IFilterCourseList } from '@types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { detectDeviceIsMobile } from '@utils';

interface IASCDSectionProps {
  onViewAllItem: (type: string) => void;
}

const ASCDSection = ({ onViewAllItem = () => null }: IASCDSectionProps) => {
  const isMobile = detectDeviceIsMobile();
  const apolloClient = useApolloClient();
  const {
    state: { currentUser },
  } = useContext(AppContext);
  /** setting date directly in query causes an infinite loop as the conditions are changing */
  const now = useRef(new Date());

  const { loading: eBooksLoading, data: getFeaturedEbooksCollections } = useQuery(EBOOKS, {
    variables: {
      ebookStatus: ['ACTIVE', 'UPCOMING'],
      filter: {
        limit: 100000,
        page: 0,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { loading: userCourseLoading, data: userCourseData } = useQuery(
    USER_LEARNING_ITEM_COLLECTIONS,
    {
      variables: { userId: [currentUser.oktaId] },
      fetchPolicy: 'network-only',
    },
  );

  const { loading: liveEventsLoading, data: liveEvents } = useQuery(LIVE_EVENTS, {
    client: contentfulDirectClient,
    variables: {
      preview: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW === 'true',
      where: {
        dateTime_gt: now.current,
        type: {
          title: 'Webinar',
        },
      },
      limit: 4,
      order: 'dateTime_ASC',
      fetchPolicy: 'network-only',
    },
  });

  const { loading: podcastLoading, data: podcast } = useQuery(PODCAST, {
    client: contentfulDirectClient,
    variables: {
      preview: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW === 'false',
      limit: 4,
      order: 'date_DESC',
    },
    fetchPolicy: 'network-only',
  });

  const { loading: webinarLoading, data: webinar } = useQuery(WEBINAR, {
    client: contentfulDirectClient,
    variables: {
      preview: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW === 'false',
      limit: 4,
      order: 'date_DESC',
    },
    fetchPolicy: 'network-only',
  });

  const { loading: videoLoading, data: video } = useQuery(VIDEO, {
    client: contentfulDirectClient,
    variables: {
      preview: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW === 'false',
      limit: 4,
      order: 'date_DESC',
    },
    fetchPolicy: 'network-only',
  });

  const { loading: pubIssuesLoading, data: pubIssues } = useQuery(GET_PUB_ISSUE, {
    client: contentfulDirectClient,
    variables: {
      preview: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW === 'false',
      pubissuesLimit: 8,
      order: 'publicationDate_DESC',
    },
    fetchPolicy: 'network-only',
  });

  const [logEvents] = useMutation(LOG_EVENTS);

  const allRecording = [
    ...(video ? video.video.items : []),
    ...(webinar ? webinar.webinar.items : []),
    ...(podcast ? podcast.podcast.items : []),
  ].sort((a, b) => Date.parse(b.date) - Date.parse(a.date));

  const userCourseTrackings: IUserLearningItemCollection[] = useMemo(() => {
    if (!userCourseLoading && get(userCourseData, 'userLearningItemCollections.length')) {
      return userCourseData.userLearningItemCollections;
    }
    return [];
  }, [userCourseLoading, userCourseData]);

  const featureEBookDataList: {
    totalCounts: number;
    ebooks: IEBook[];
  } = get(getFeaturedEbooksCollections, 'ebooks', {
    ebooks: [],
    totalCounts: 0,
  });
  const featureEBookList = featureEBookDataList.ebooks
    .filter((learningItemCollection: IEBook) => learningItemCollection.status === 'PUBLISHED')
    .map((learningItemCollection: IEBook) => ({
      ...learningItemCollection,
      coverUrl: learningItemCollection?.imageName,
    }));

  // when click on ebooks it's fetch url and open pdf
  const onOpenEBook = async (item?: IEBook | ICourse | IFilterCourseList) => {
    if (!Object.prototype.hasOwnProperty.call(item, 'LockLizardBookId')) return;
    if ('LockLizardBookId' in (item as ICourse | IEBook | IFilterCourseList)) {
      const response = await apolloClient.query({
        query: GET_EBOOK_URL,
        variables: { docId: (item as IEBook)?.LockLizardBookId },
      });
      if (response?.data?.getEbookUrl) {
        logEvents({
          variables: {
            data: item,
            logType: 'EBOOK',
            actionType: 'VIEWED',
          },
        });
        window.open(response.data.getEbookUrl, '_blank');
      }
    }
  };

  // when click on any live event it's redirect to ASCD website
  const handleSelectEvent = (item: ICourse | IEBook | IFilterCourseList) => {
    window.open(`https://www.ascd.org/${item?.__typename?.toLowerCase()}s/${item?.slug}`);
  };
  const handleSelectMagazineEvent = (href: string) => {
    const magazineUrl = process.env.EL_MAGAZINE_URL;
    if (!!magazineUrl) {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = magazineUrl;
      iframe.id = 'elMagazineId@123';
      document.body.appendChild(iframe);

      const idElement = document.getElementById('elMagazineId@123');

      if (idElement) {
        window.open(`https://www.ascd.org/el/${href}#about-section`, '_blank');
      } else {
        iframe.onload = () => {
          setTimeout(() => {
            window.open(`https://www.ascd.org/el/${href}#about-section`, '_blank');
          }, 500);
        };
      }
    } else {
      window.open(`https://www.ascd.org/el/${href}#about-section`, '_blank');
    }
  };

  const fetchImage = async (imageName: string) => {
    const MINUTE_MS = 60000;
    const defaultCacheImages = localStorage.getItem('images');
    let localStorageImages = defaultCacheImages ? JSON.parse(defaultCacheImages) : {};
    if (localStorageImages[`${imageName}`]) return localStorageImages[`${imageName}`].image;

    const response = await apolloClient.query({
      query: GET_IMAGE_URL,
      variables: { imageName },
      fetchPolicy: 'network-only',
    });

    const image = response?.data?.getImageUrl?.url || '';
    const currentTime = new Date();
    localStorageImages = {
      ...localStorageImages,
      [imageName]: {
        image,
        expiry: currentTime.getTime() + MINUTE_MS * 14,
      },
    };

    localStorage.setItem('images', JSON.stringify(localStorageImages));
    return image;
  };

  return (
    <>
      <Grid item xs={6} sm={12} md={12} xl={12} lg={12} data-testid="featured-Ebooks">
        <LearningItemCardList
          loading={eBooksLoading}
          defaultExpanded
          btnTitle="View All"
          title="Featured Ebooks"
          fetchImage={fetchImage}
          courseData={featureEBookList}
          count={featureEBookList.length}
          handleSelectCourse={onOpenEBook}
          sectionTitle={HOME_SECTION_NAME.EBOOKS}
          userCourseTrackings={userCourseTrackings}
          handleViewAll={() => onViewAllItem(HOME_SECTION_NAME.EBOOKS)}
          currentUser={currentUser}
        />
      </Grid>
      <Grid item xs={6} sm={12} md={12} xl={12} lg={12}>
        <LearningItemCardList
          loading={pubIssuesLoading}
          defaultExpanded
          title="EL Magazine"
          isDateVisible
          hideProgress
          btnTitle="View all"
          courseData={pubIssues?.publications?.items}
          count={pubIssues?.publications?.items?.length}
          handleSelectMagazineEvent={handleSelectMagazineEvent}
          handleSelectCourse={(item?: ICourse | IEBook | IFilterCourseList) => {
            logEvents({
              variables: {
                data: item,
                actionType: 'VIEWED',
                logType: 'EL_MAGAZINE',
              },
            });
          }}
          handleViewAll={() =>
            isMobile
              ? onViewAllItem(HOME_SECTION_NAME.EL_MAGAZINE)
              : window.open(`https://www.ascd.org/el/all`)
          }
        />
      </Grid>
      <Grid item xs={6} sm={12} md={12} xl={12} lg={12} data-testid="live-Events">
        <LearningItemCardList
          loading={liveEventsLoading}
          hideProgress
          isDateVisible
          isLiveEvent
          defaultExpanded
          fetchImage={fetchImage}
          title="Live Events"
          btnTitle="View All"
          handleSelectCourse={(item?: ICourse | IEBook | IFilterCourseList) => {
            logEvents({
              variables: {
                data: item,
                logType: 'WEBINAR',
                actionType: 'VIEWED',
              },
            });
            handleSelectEvent(item as ICourse | IEBook | IFilterCourseList);
          }}
          courseData={liveEvents?.liveEvents?.items || []}
          sectionTitle={HOME_SECTION_NAME.LIVE_EVENTS}
          count={liveEvents?.liveEvents?.items.length}
          currentUser={currentUser}
          handleViewAll={() => onViewAllItem(HOME_SECTION_NAME.LIVE_EVENTS)}
        />
      </Grid>
      <Grid item xs={6} sm={12} md={12} xl={12} lg={12} data-testid="recordings">
        <LearningItemCardList
          loading={webinarLoading || podcastLoading || videoLoading}
          hideProgress
          isDateVisible
          isLiveEvent
          defaultExpanded
          fetchImage={fetchImage}
          title="Recordings"
          btnTitle="View All"
          courseData={allRecording || []}
          count={allRecording.length}
          handleSelectCourse={(item?: ICourse | IEBook | IFilterCourseList) => {
            logEvents({
              variables: {
                data: item,
                logType: 'WEBINAR',
                actionType: 'VIEWED',
              },
            });
            handleSelectEvent(item as ICourse | IEBook | IFilterCourseList);
          }}
          sectionTitle={HOME_SECTION_NAME.LIVE_EVENTS}
          handleViewAll={() => onViewAllItem(HOME_SECTION_NAME.AVAILABLE_RECORDINGS)}
          currentUser={currentUser}
        />
      </Grid>
    </>
  );
};

export { ASCDSection };
