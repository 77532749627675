import { useRouter } from 'next/router';
import { Divider, Breadcrumbs } from '@ascd/witsby-components';
import { Box } from '@mui/material';
import { Animation } from '@components/Animation';
import HomeSections from '@components/HomeSections/HomeSections';
import Layout from '@components/Layout';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SubHeader from '@components/SubHeader/SubHeader';
import { MyPageContext } from '@types';
import { detectDeviceIsMobile, detectDeviceIsTablet } from '@utils';

const breadcrumbList = [
  {
    icon: 'HOME',
    pathName: '/',
    routeName: 'Home',
  },
];

const Home: MyPageContext = () => {
  const router = useRouter();

  const isMobile = detectDeviceIsMobile();
  const isTablet = detectDeviceIsTablet();

  return (
    <>
      <Animation />
      <SubHeader />

      {!isMobile && (
        <Box sx={{ display: 'flex', width: '100%', height: '50px' }}>
          <Box
            sx={(theme) => ({
              minWidth: isTablet ? '170px' : '264px',
              [theme.breakpoints.up('lg')]: {
                width: 'calc((100vw - 867px)/2)',
                minWidth: '320px',
              },
              [theme.breakpoints.down('md')]: {
                minWidth: '86px',
                width: '86px',
              },
            })}
          />
          <Box
            display="flex"
            alignItems="center"
            sx={{ width: isTablet ? 'calc(100vw - (86px*2))' : '867px' }}>
            <Breadcrumbs
              routeList={breadcrumbList}
              onClick={(pathName: string | undefined) => router.push(pathName || '/')}
            />
          </Box>
        </Box>
      )}
      <Divider />
      <HomeSections />
    </>
  );
};

Home.Layout = Layout;

export default Home;
